@import "variables";
@import "common";

.Faq {
  @extend .font__content;

  .Faq__container {
    @extend .container;
  }

  .Faq__title {
    @extend .title
  }

  .Faq__content {
    text-align: left;
    margin: 3%;
  }

  .Faq__content>div {
    padding-top: 1%;
  }

}