@import 'variables';
@import 'common';

.about-us {
  @extend .font__content;

  .about-us__container {
    @extend .container;
  }

  .about-us__title {
    @extend .title;
  }

  .about-us__gcg {
    @extend .title;
    text-align: left;
  }

  .gcg__content {
    text-align: left;
  }

  .gcg__content > div {
    padding-bottom: 1%;
  }

  .ui.items > .item {
    @extend .padding__content;
    border-top: 3px solid $grey;
    border-bottom: 3px solid $grey;
  }

  .ui.items > .item > .content {
    @extend .flex__content;
  }

  .ui.items > .item > .content > .description {
    color: $title-green;
    font-weight: bold;
  }

  .about-us__mission {
    @extend .mission__content;
  }

  .about-us__text {
    @extend .mission__text;
  }

  .ui.card {
    @extend .mission__card;
  }

  .ui.card > .image {
    @extend .mission__cardImage;
  }

  .footer__subcontact {
    @extend .title;
    width: fit-content;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  a > .footer__subcontact > div {
    text-decoration: underline;
  }
}
