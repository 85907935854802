@import "variables";

.header {
  .ui.menu .item .imgContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    >img {
        max-width: 150px;
        width: 50%;
      }
  }
}

.ui.item.menu{
  border: 0px;
  border-radius: 0;
  border-bottom: 5px solid $light-green;
  padding: 0 8rem;
  min-height: 80px;
}


@media only screen and (max-width: #{$medium}px) {
  .header {
      .ui.menu .item .imgContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        >img {
          max-width: 150px;
          width: 70%;
        }
      }
    }
}