@import "variables";
@import "common";
.top-line{
  background-clip: content-box;   
  width: 100%;
  height: 1px;
  padding: 0 5%;
  background-color:  #00000017;
}
.footer {
  bottom: 0;
  width: 100%;
  padding: 3% 5% 2%;
  font-size: 14px;
  background-image: url('.././images/footer-bg.png');
  background-size: 84%;
  background-position: bottom right;;
  background-repeat: no-repeat;
  @media only screen and (max-width: #{$medium}px) {
    padding: 5% 0 0;
    font-size: calc((100vw * 16) / #{$medium});
  }

  @media only screen and (max-width: #{$small}px) {
    padding: 5% 0 0;
    font-size: calc((100vw * 16) / #{$small});
  }

  .footer__title {
    height: 24px;
    font-size: calc((100vw * #{$h2}) / #{$large});
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 20px;
    p {
      text-align: left;
    }
    color :rgba(63, 105, 90, 1); 
    @media only screen and (max-width: #{$medium}px) {
      text-align: center;
      font-size: calc((100vw * #{$h2}) / #{$medium});
      p {
        text-align: center;
      }
    }

    @media only screen and (max-width: #{$small}px) {
      text-align: center;
      font-size: calc((100vw * #{$h2}) / #{$small});
      p {
        text-align: center;
      }
    }
    .finance-logo {
      width: 100px;

    }
  
  }
 
  .footer__logo {
    width: fit-content;
    padding-right: 1%;
    margin: auto;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: #{$medium}px) {
      border-radius: 0;
      margin: 0;
      padding: 0 0 5%;
    }

    @media only screen and (max-width: #{$small}px) {
      border-radius: 0;
      margin: 0;
      padding: 0 0 5%;
    }
  }

  .footer__institution {
    padding: 0 15%;
    @extend .font__content;

    @media only screen and (max-width: #{$medium}px) {
      padding: 0 3%;
    }

    @media only screen and (max-width: #{$small}px) {
      padding: 0 3%;
    }
  }

  .appi__logo {
    padding-top: 3%;
  }

  .footer__ojkappi {
    width: 100%;
    text-align: center;
    font-weight: bolder;
    font-size: 30px;

    @media only screen and (max-width: #{$medium}px) {
      font-size: 15px;
    }

    @media only screen and (max-width: #{$small}px) {
      font-size: 15px;
    }
  }

  a {
    color:  rgba(0, 0, 0, 0.65);
    display: block;
  }


  a:hover {
    color: #569f85;
  }

  table {
    width: 100%;
  }

  .table-content {
    padding-bottom: 2%;
    
  }

  .footer__link {
    text-align: center;
    padding-bottom: 5%;
  }

  .footer__url {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    text-align: left;

    @media only screen and (max-width: #{$medium}px) {
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (max-width: #{$small}px) {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .text-with-bottom {
      margin-bottom: 8px;
    }
  }

  .fa {
    padding-right: 5px;
    font-size: 25px;

    @media only screen and (max-width: #{$medium}px) {
      text-align: right;
      font-size: calc((100vw * #{$h2}) / #{$medium});
    }

    @media only screen and (max-width: #{$small}px) {
      text-align: right;
      font-size: calc((100vw * #{$h2}) / #{$small});
    }

  }

  .footer__location {
    text-align: left;
    color: rgba(0, 0, 0, 0.65);
    .text-with-bottom{
      margin-bottom: 8px;
    }
  }

  .footer__socmed {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;

    @media only screen and (max-width: #{$medium}px) {
      flex-direction: column;
    }

    @media only screen and (max-width: #{$small}px) {
      flex-direction: column;
    }
  }

  .footer__contact {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.65);
    // @media only screen and (max-width: #{$medium}px) {
    //   padding: 0 10%;
    // }

    // @media only screen and (max-width: #{$small}px) {
    //   padding: 0 10%;
    // }
    .text-with-bottom {
      margin-bottom: 8px;
    }
  }

  .footer__contact>a {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      padding-right: 4px;
    }
  }

  .footer__watcher {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 3%;
    padding: 3% 0 0;
    margin-bottom: 8px;
    @media only screen and (max-width: #{$medium}px) {
      display: block;
    }
    @media only screen and (max-width: #{$small}px) {
      display: block;
    }
    .footer_desc{
      line-height: 18px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);;
    }
  }

  li::before {
    margin-left: -1.5rem !important;
    color: #fff !important;
  }

  .footer__subcontact {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .icon{
      width: 16px;
      margin-right: 4px;
    }
  }

  a>.footer__subcontact>div {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.65);
  }

  .footer__logo_group {
    display: flex;
    align-items: end;
    justify-content: center;
    margin-bottom: 17px;
    gap: 8px;
    .img-large {
      height: calc((100vw * 60) / #{$large});
      @media only screen and (max-width: #{$medium}px) {
        height: calc((100vw * 60) / #{$medium});
      }
      @media only screen and (max-width: #{$small}px) {
        height: calc((100vw * 60) / #{$small});
      }
    }
    .img-medium{
      height: calc((100vw * 60) / #{$large}*(2/3));
      @media only screen and (max-width: #{$medium}px) {
        height: calc((100vw * 60) / #{$medium}*(2/3));
      }
      @media only screen and (max-width: #{$small}px) {
        height: calc((100vw * 60) / #{$small}*(2/3));
      }
    }
  }
}