@import "variables";
@import "common";

.ourteam {
  @extend .font__content;

  .ourteam__container {
    @extend .container;
  }

  .ourteam__title {
    @extend .title;
  }

  .ourteam__gcg {
    @extend .title;
    text-align: left;
  }

  .ui.items>.item {
    @extend .padding__content;
    border-top: 3px solid $grey;
    border-bottom: 3px solid $grey;
  }

  .ui.items>.item>.content {
    @extend .flex__content;
  }

  .ui.items>.item>.content>.description {
    color: $title-green;
    font-weight: bold;
  }
}