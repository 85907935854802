$midnight: #32754c;
$clouds: #ecf0f1;

input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

// Layout
.row {
  display: flex;

  .col {
    flex: 1;

    &:last-child {
      margin-left: 1em;
    }
  }
}

/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;

  .tab-content {
    white-space: pre-wrap;

    li {
      padding-top: 5px;
    }
  }

  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: $midnight;
    font-weight: bold;
    cursor: pointer;

    /* Icon */
    &:hover {
      background: darken($midnight, 10%);
    }

    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all .35s;
    }
  }

  &-content {
    max-height: 0;
    padding: 0 1em;
    color: $midnight;
    background: white;
    transition: all .35s;
  }

  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: $midnight;
    cursor: pointer;

    &:hover {
      background: darken($midnight, 10%);
    }
  }
}

// :checked
input:checked {
  +.tab-label {
    background: darken($midnight, 10%);

    &::after {
      transform: rotate(90deg);
    }
  }

  ~.tab-content {
    max-height: 100vh;
    padding: 1em;
  }
}


.accordion__answer {
  text-align: left;
}
