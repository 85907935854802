@import 'variables';

.title {
  font-size: calc((100vw * #{$h2}) / #{$large});
  margin: 2% 0;
  color: $title-green;

  @media only screen and (max-width: #{$medium}px) {
    font-size: calc((100vw * #{$h2}) / #{$medium});
  }

  @media only screen and (max-width: #{$small}px) {
    font-size: calc((100vw * #{$h2}) / #{$small});
  }
}

.container {
  min-height: 45vh;
  padding: 0 2% 5%;
}

.padding__content {
  padding: 10px;
}

.flex__content {
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;

  @media only screen and (max-width: #{$medium}px) {
    text-align: center;
  }
}

.font__content {
  font-size: calc((100vw * 14) / #{$large});

  @media only screen and (max-width: #{$medium}px) {
    font-size: calc((100vw * 14) / #{$medium});
  }

  @media only screen and (max-width: #{$small}px) {
    font-size: calc((100vw * 14) / #{$small});
  }
}

.mission__content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: #{$medium}px) {
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: #{$small}px) {
    flex-direction: column;
    align-items: center;
  }
}

.mission__text {
  display: flex;
  align-items: flex-start;
  height: calc((100vw * 35) / #{$medium});
  text-align: left !important;
  padding: 3%;

  @media only screen and (max-width: #{$medium}px) {
    height: fit-content;
  }

  @media only screen and (max-width: #{$small}px) {
    height: fit-content;
  }
}

.mission__card {
  margin: 1% 5% !important;

  @media only screen and (max-width: #{$medium}px) {
    height: auto;
    margin-bottom: 5% !important;
  }

  @media only screen and (max-width: #{$small}px) {
    height: auto;
    margin-bottom: 5% !important;
  }
}

.mission__cardImage {
  width: 290px;
  height: 290px;
  object-fit: cover;

  @media only screen and (max-width: #{$medium}px) {
    width: 100%;
  }

  @media only screen and (max-width: #{$small}px) {
    width: 100%;
  }
}
