@import "variables";
@import "common";

.home {
  @extend .font__content;

  a {
    color: $white;
  }

  .home__contact {
    color: $green;
    padding: 1%;
    font-size: large;
  }

  .home__container {
    @extend .container;
  }

  .home__title {
    @extend .title;
  }

  .title__banner {
    padding: 5%;
    color: #ffffff;
    font-weight: bolder;
    line-height: normal;

    @media only screen and (max-width: #{$medium}px) {
      font-weight: bold;
      font-size: calc((100vw * 14) / #{$medium});
      padding: 0;
    }

    @media only screen and (max-width: #{$small}px) {
      font-weight: bold;
      font-size: calc((100vw * 14) / #{$small});
      padding: 0;

    }
  }

  .title__banner>div {
    padding: 1%;
  }

  .home__mission {
    @extend .mission__content;
  }


  .ui.card {
    @extend .mission__card;
  }

  .ui.card>.image {
    @extend .mission__cardImage;
  }



  .home__header-image {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .home__title {
      position: absolute;
      z-index: 999;
      width: 100%;
      left: 0;
      right: 0;

    }

    a {
      position: absolute;
      z-index: 999;
      width: 100%;
      left: 0;
      right: 0;


      bottom: 15%;
    }

    img {
      width: 100vw;
    }
  }

  .home__text {
    @extend .mission__text;
  }
}